import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
// import { SignUpAction } from 'store/actions/authAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [activeTab, setActiveTab] = useState('NormalAuth');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    agree: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const toggle = tab => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }

    if (!formData.password.trim()) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 7) {
      errors.password = 'Password must be at least 7 characters long';
    }

    if (!formData.agree) {
      errors.agree = 'Please agree to the Privacy Policy';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setLoading(true);
      const payload = {
        UserName: formData.name,
        Email: formData.email,
        Password: formData.password,
        Provider: 'Cognito'
      };

      // dispatch(SignUpAction(payload, 
      //   (data) => {
      //     toast.success("Successfully signed up");
      //     history.push('/auth/verifyotp');
         

      //     setLoading(false);
      //   }, 
      //   (errorMessage) => {
      //     toast.error(errorMessage);
      //     setLoading(false);
      //   }
      // ));
    }
  };

  return (
    <>
      <ToastContainer />
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-4">
          <div className="text-center text-muted mb-5">
            <span className="text-md d-flex justify-content-center">Sign Up</span>
            </div>
            {/* <Nav  className=" mb-4 cursor-pointer" tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'NormalAuth' })}
                  onClick={() => { toggle('NormalAuth'); }}
                >
                  Sign Up with Credentials
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'Cognito' })}
                  onClick={() => { toggle('Cognito'); }}
                >
                  Sign Up with Cognito
                </NavLink>
              </NavItem>
            </Nav> */}
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    invalid={!!errors.name}
                    disabled={loading}
                  />
                  <div className="invalid-feedback">{errors.name}</div>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    invalid={!!errors.email}
                    disabled={loading}
                  />
                  <div className="invalid-feedback">{errors.email}</div>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    invalid={!!errors.password}
                    disabled={loading}
                  />
                  <div className="invalid-feedback">{errors.password}</div>
                </InputGroup>
              </FormGroup>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox text-left">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      name="agree"
                      checked={formData.agree}
                      onChange={(e) =>
                        setFormData({ ...formData, agree: e.target.checked })
                      }
                      invalid={!!errors.agree}
                    />
                    <label
                    className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the 
                        <a href="#pablo" className="pl-1" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                    <div className="invalid-feedback">{errors.agree}</div>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit" disabled={loading}>
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    'Continue'
                  )}
                </Button>
              </div>
            </Form>

          </CardBody>
            <small className='my-3'>Already have an account? <span className='text-primary cursor-pointer' style={{cursor: 'pointer'}} onClick={() => history.push("/auth/login")}>Sign In</span></small>
        </Card>
      </Col>
    </>
  );
};

export default Register;
