import React from "react";
import { Spinner } from "reactstrap";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const DeleteModal = (props) => {
  return (
    <>
      <Modal isOpen={true} >
        <ModalHeader>ALERT!</ModalHeader>
        <ModalBody>
          <h5 className="text-center ">
            Are you sure you want to delete?
          </h5>

          <div className="d-flex justify-content-center align-items-center my-3">
            <Button  className="bg-primary text-white w-25 mx-2" onClick={props?.DeleteHandler} disabled={props?.loading}>
              Yes
               {props?.loading && <Spinner size="sm" color="light" />}
              </Button>
              

            <Button
              className="bg-danger text-white w-25"
              onClick={props?.closeModal}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default DeleteModal;
