import { LOADER, LOGIN, LOGOUT, SIGNUP } from "../types";
import { toast } from "react-toastify";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { error } from "jquery";
var auth = RepositoryFactory.get("auth");

export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: LOADER, payload: val });
};
export const SignUpAction =
  (payload, onSuccess, onError) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.signuprep(payload);
      if (data?.status_code == 200) {
        dispatch({ type: SIGNUP, payload: payload });
        // toast.success(data?.detail)
        onSuccess(data);
      } else {
        toast.warning(data?.detail);
        onError(data.detail || "Something went wrong during signup.");
      }
    } catch (e) {
      console.error("Signup error:", e);
      onError(e.message || "An error occurred during signup.");
    } finally {
      await dispatch(setAuthLoading(false));
    }
  };

export const LoginAction =
  (payload, onSuccess, onError) => async (dispatch) => {
    console.log("payload", payload);

    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.loginrep(payload);
      console.log("data===>", data);
      if (data?.status_code == 200) {
        dispatch({ type: LOGIN, payload: data });
        onSuccess(data);
        toast.success(data?.detail);
      } else {
        toast.warning(data?.detail);
		onError(data?.detail)
        // if (typeof onError === 'function') {
        // 	onError(data.detail || 'Something went wrong during login.');
        // } else {
        // 	console.error('onError is not a function');
        // }
      }
    } catch (e) {
      console.error("Login error:", e);
      if (typeof onError === "function") {
        onError(e.message || "An error occurred during login.");
      } else {
        console.error("onError is not a function");
      }
    } finally {
      await dispatch(setAuthLoading(false));
    }
  };

//Update password

export const UpdatePasswordAction =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.UpdatePasswordRep(payload);
      console.log(data);
      if (data?.status_code === 200) {
        onSuccess();
        // toast.success(data?.detail);
        await dispatch(setAuthLoading(false));
      } else {
        // toast.error(data?.detail);
        await dispatch(setAuthLoading(false));
      }
    } catch (e) {
      // toast.error(e);
      await dispatch(setAuthLoading(false));
    }
  };

export const VerifyAction =
  (payload, onSuccess, onError) => async (dispatch) => {
    console.log("payload", payload);
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await auth.verifyrep(payload);
      await dispatch(setAuthLoading(false));
      if (data?.status_code === 200) {
        toast.success(data.detail);
        dispatch({ type: LOGIN, payload: data });
        onSuccess();
      } else {
        toast.error(data?.detail);
        await dispatch(setAuthLoading(false));
        onError();
      }
    } catch (e) {
      // toast.error(e.message);
      await dispatch(setAuthLoading(false));
      dispatch(setAuthLoading(false));
    }
  };

export const login = () => async (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: { id: 123, first_name: "abc", last_name: "def" },
  });
};

export const logout = (onSuccess) => async (dispatch) => {
  localStorage.clear();
  onSuccess();
  dispatch({
    type: LOGOUT,
  });
};
