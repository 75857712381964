import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import { useParams,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  personalknowledgeAction,
  getAvatarDefaultDataAction,
  getPersonalknowledgeAction
} from "store/actions/avatarAction";
import { getAdvancePersonalityAction } from "store/actions/avatarAction";
const Text = () => {
  const location = useLocation();
  const { submitLoading,getAdvancePersonality,avatarData } = useSelector((state) => state?.create);
  const {Id,title,imageUrl} = location?.state?.data;
  const dispatch = useDispatch();
  const [textRows, setTextRows] = useState(0);
  const [lineNumbers, setLineNumbers] = useState([]);
  const [inputText, setInputText] = useState("");


  useEffect(() => {
    dispatch(getAdvancePersonalityAction(Id));
  }, []);

  useEffect(() => {
    if (getAdvancePersonality?.PersonalKnowledge) {
      setInputText(getAdvancePersonality.PersonalKnowledge);
      updateLineNumbers(getAdvancePersonality.PersonalKnowledge);
    }
  }, [getAdvancePersonality]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleInputChange(e);
      const trimmedText = inputText.trim();
      if (trimmedText !== "") {
        setInputText((prevText) => prevText + "\n");
      }
    }
  };

  const updateLineNumbers = (text) => {
    if (!text) {
      setLineNumbers([]);
      setTextRows(1);
      return;
    }
    const lines = text.split("\n").length;
    const numbers = [];
    for (let i = 1; i <= lines; i++) {
      numbers.push(i);
    }
    setLineNumbers(numbers);
    setTextRows(lines);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputText(value);
    updateLineNumbers(value);
  };
  const onSubmit = () => {
    let payload = {
      AvatarId: Id,
      CharacterTraits: getAdvancePersonality?.CharacterTraits,
      MoodSadnessJoy: getAdvancePersonality?.MoodSadnessJoy,
      MoodAngerFear: getAdvancePersonality?.MoodAngerFear,
      MoodDisgustTrust: getAdvancePersonality?.MoodDisgustTrust,
      MoodAnticipationSurprise: getAdvancePersonality?.MoodAnticipationSurprise,
      MoodStaticDynamicEmotions: getAdvancePersonality?.MoodStaticDynamicEmotions,
      PersonalityNegativePositive: getAdvancePersonality?.PersonalityNegativePositive,
      PersonalityAggressivePeaceful: getAdvancePersonality?.PersonalityAggressivePeaceful,
      PersonalityCautiousOpen: getAdvancePersonality?.PersonalityCautiousOpen,
      PersonalityIntrovertExtravert: getAdvancePersonality?.PersonalityIntrovertExtravert,
      PersonalityInsecureConfident: getAdvancePersonality?.PersonalityInsecureConfident,
      PersonalKnowledge: inputText,
      isPersonalKnowledgePublic: true

    };
    dispatch(personalknowledgeAction(payload,()=>{
      dispatch(getPersonalknowledgeAction(Id));
    }));
  };

  return (
    <div>
      <div className="Outer-InputTrait">
        <p id="Character-Trait">Personal knowledge </p>
        <div className="tag-containerKC">
          <div className="line-numbers">
            {lineNumbers.map((num) => (
              <div key={num} className="line-number">
                {num}
              </div>
            ))}
          </div>
          <textarea
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            rows={textRows}
            className="InputPersonal"
            placeholder="Enter facts (one per line) that your character should know. For example:

            {character} has an evil twin named John
            {character}&#39;s favorite ice cream flavor is strawberry
            {character} believes that VR-based education will revolutionize learning
            {character} learned how to cook from his father when he was young"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4 px-4">
        <button
          className="btn btn-primary pe-auto text-end"
          onClick={onSubmit}
          disable={submitLoading}
        >
          Submit
          {submitLoading && <Spinner size="sm" color="light" />}
        </button>
      </div>
    </div>
  );
};

export default Text;
