import React, { useState, useEffect } from 'react';
import EditLogo from '../../assets/img/theme/team-1-800x800.jpg';
import Personality from './Advanced/Personality';
import KnowCognito from './Advanced/Know-Cognito/Know-Cognito';
import OnlyHeader from 'components/Headers/OnlyHeader';
import Basic from 'components/EditAvatar/Basic/Basic';
import ImageUpload from 'react-image-easy-upload';
import { useDispatch } from 'react-redux';
import { updateProfilePhotoAction } from 'store/actions/avatarAction';
import { useParams, useLocation } from 'react-router-dom';
import { getAvatar } from 'store/actions/avatarAction';
import { getAllAvatar } from 'store/actions/avatarAction';
const EditAvatar = () => {
	const location = useLocation();
	const { Id, title, imageUrl } = location?.state?.data;
	const dispatch = useDispatch();
	const [active, setActive] = useState(2);
	const [isEdit, setIsEdit] = useState(false);

	const [activeBasic, setActiveBasic] = useState(2);
	const [file, setFile] = useState('');
	const handlePer = () => {
		setActive(1);
	};
	useEffect(() => {
		if (imageUrl) {
			setIsEdit(false);
		}
	}, []);

	const handleKC = () => {
		setActive(2);
	};
	const handleBasic = () => {
		setActiveBasic(1);
	};
	const handleAdvanced = () => {
		setActiveBasic(2);
	};
	const handleImageUpload = imageData => {
		setFile(imageData);
		const formData = new FormData();
		formData.append('File', imageData);
		dispatch(updateProfilePhotoAction(Id, formData, () => {
			dispatch(getAvatar());
			dispatch(getAllAvatar());
		}));
	};

	return (
		<>
			<OnlyHeader />
			<div className='main-box'>
				<div className='outer-info'>
					<div className='Char-Info'>
						<div className='text-center'>
							{/* {renderedContent} */}
							{imageUrl ? (
								isEdit ? (
									<ImageUpload
										setImage={handleImageUpload}
										width='130px'
										height='130px'
										shape='circle'
										border={true}
										backgroundColor='white'
										borderColor='blue'
									/>
								) : (
									<img
										src={imageUrl}
										width='130px'
										height='130px'
										alt='Uploaded Avatar'
									/>
								)
							) : (
								<ImageUpload
									setImage={handleImageUpload}
									width='130px'
									height='130px'
									shape='circle'
									border={true}
									backgroundColor='white'
									borderColor='blue'
								/>
							)}
						</div>

						<div className='Per-Info'>
							<h1>{title}</h1>
							<p>Tsuki AI</p>
							<button
								className='Edit-Detail'
								onClick={() => setIsEdit(!isEdit)}
							>
								Edit Profile Picture
							</button>
						</div>
					</div>
					<div className='Options'>
						<button
							onClick={() => {
								handleBasic();
							}}
							className={`${
								activeBasic === 1
									? 'optionsOuterActive'
									: 'notActiveOuter'
							} `}
						>
							Basic
						</button>
						<button
							onClick={() => {
								handleAdvanced();
							}}
							className={`${
								activeBasic === 2
									? 'optionsOuterActive'
									: 'notActiveOuter'
							} `}
						>
							Advanced
						</button>
					</div>
				</div>
				{activeBasic === 1 ? (
					<Basic Id={Id} />
				) : (
					<>
						<div className='Options'>
							<button
								onClick={() => {
									handlePer();
								}}
								className={`${
									active === 1 ? 'optionsActive' : 'notActive'
								} `}
							>
								Personality
							</button>
							<button
								onClick={() => {
									handleKC();
								}}
								className={`${
									active === 2 ? 'optionsActive' : 'notActive'
								} `}
							>
								Knowledge & Cognition
							</button>
						</div>
						{active === 1 ? <Personality /> : <KnowCognito />}
					</>
				)}
			</div>
		</>
	);
};

export default EditAvatar;
