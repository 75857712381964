import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { addBasicInfo, getBasicInfoAction } from "store/actions/avatarAction";

const Basic = ({ Id }) => {
  const dispatch = useDispatch();
  const { getBasicInfo,submitLoading } = useSelector((state) => state?.create);
  const [inputText, setInputText] = useState("");
  const [inputTextTwo, setInputTextTwo] = useState("");

  useEffect(() => {
    dispatch(getBasicInfoAction(Id));
  }, []);
  useEffect(() => {
    setInputText(getBasicInfo?.Motivations);
    setInputTextTwo(getBasicInfo?.Flaws);
  }, [getBasicInfo]);

  const onSubmit = () => {
    const payload = {
      AvatarId: Id,
      Motivations: inputText,
      Flaws: inputTextTwo,
    };

    dispatch(
      addBasicInfo(payload, () => {
        dispatch(getBasicInfoAction(Id));
        toast.success('Info Updated Successfully');
      })
    );
  };

  // This is Used to Increase the Size of the Textarea With the Increase of the Content and to Avoid the Scrollbar
  const handleInputChange = (event) => {
    setInputText(event.target.value);
    adjustTextareaHeight(event.target);
  };
  const handleInputChangeTwo = (event) => {
    setInputTextTwo(event.target.value);
    adjustTextareaHeight(event.target);
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
  };

  return (
    <div className="Outer-Basic">
      <div className="Motivation">
        <h1>Motivation</h1>

        <textarea
          value={inputText}
          onChange={handleInputChange}
          className="MotivationText"
          placeholder="Write a short description that describes who your character is "
        />
        {/* <p>Core Description is your character's foundation. Include their backstory, circumstances, and behaviors or rules.</p> */}
      </div>

      <div className="Flaw">
        <h1>Flaws</h1>

        <textarea
          value={inputTextTwo}
          onChange={handleInputChangeTwo}
          className="MotivationText"
          placeholder="Write a 1-2 sentence of the character`s flaws,challanges, and/or insecurities"
        />
      </div>

      <div className="">
        <button
          className="btn btn-primary pe-auto text-end"
          onClick={onSubmit}
          disable={submitLoading}
        >
          Submit {submitLoading && <Spinner size='sm' color='light' />}
        </button>
      </div>
    </div>
  );
};

export default Basic;
