export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const AUTH_SET_LOADING = 'AUTH_SET_LOADING';
export const AUTH_NOTIFICATION = 'AUTH_NOTIFICATION';
export const LOADER = 'LOADER';
export const PATIENT_LOADER = 'PATIENT_LOADER';
export const ADD_PATIENT = 'ADD_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const GET_PATIENT = 'GET_PATIENT';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const SHOW_PATIENT = 'SHOW_PATIENT';
export const GET_SETTING = 'GET_SETTING';
export const SETTING_LOADER = 'SETTING_LOADER';
export const SESSION_LOADER = 'SESSION_LOADER';
export const SESSION_CREATE = 'SESSION_CREATE';
export const GET_SESSION = 'GET_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';

export const CREATEAVATAR = 'CREATEAVATAR';
export const GETUSERAVATAR = 'GETUSERAVATAR';
export const GETALLAVATAR = 'GETALLAVATAR';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const GET_AVATAR = 'GET_AVATAR';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const GET_AVATAR_Files = 'GET_AVATAR_Files';
export const DELETE_AVATAR_FILES = 'DELETE_AVATAR_FILES';
export const GET_PERSONAL_KNOWLEDGE = 'GET_PERSONAL_KNOWLEDGE';
export const GET_ADVANCE_PERSONALITY = 'GET_ADVANCE_PERSONALITY';
export const GET_ADVANCE_INFO = 'GET_ADVANCE_INFO';
export const SET_AVATAR_DATA = 'SET_AVATAR_DATA';
export const DELETE_AVATAR_FILES_LOADER = 'DELETE_AVATAR_FILES_LOADER';
export const ANALYTICS = 'ANALYTICS';
export const ACTIVEUSERS = 'ACTIVEUSERS';
export const USERLOADER = 'USERLOADER';
export const ACTIVEUSERLOADER = 'ACTIVEUSERLOADER';
export const DELETEAVATAR = 'DELETEAVATAR';

