import React from 'react';
import { ReactMic } from 'react-mic';

const AudioRecorder = ({ record, onStop, onData }) => {
	return (
		<div className='d-flex justify-content-enter'>
			<ReactMic
				record={record}
				className='sound-wave'
				onStop={onStop}
				visualSetting='frequencyBars'
				onData={onData}
				strokeColor='#0EAAC9'
				// backgroundColor='#0EAAC9' // WhatsApp-like pink background
			/>
		</div>
	);
};

export default AudioRecorder;
