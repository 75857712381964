import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { VerifyAction } from 'store/actions/authAction';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap/es';

const VerifyOTP = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const auth = useSelector(state => state.authUser);
	const { loading } = useSelector(state => state?.create);
	const [loader, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		otp: '',
		Email: auth?.email,
		Provider: auth?.provider,
	});

	console.log('FormData', formData);

	const [errors, setErrors] = useState({});

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const validateForm = () => {
		const errors = {};

		if (!formData.otp.trim()) {
			errors.otp = 'OTP is required';
		}
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	console.log('Store Data', auth);

	console.log('SignUp Data', formData);
	const handleSubmit = e => {
		e.preventDefault();

		setLoading(true);
		const payload = {
			Email: auth?.email,
			Provider: auth?.provider,
			OTP: formData.otp,
		};
		console.log('Payload', payload);
		const isValid = validateForm();
		dispatch(
			VerifyAction(
				payload,
				() => {
					history.push('/auth/login');
					setLoading(false);
				},
				() => {
					setLoading(false);
				}
			)
		);
	};

	return (
		<>
			<Col lg='6' md='8'>
				<Card className='bg-secondary shadow border-0'>
					<CardBody className='px-lg-5 py-lg-5'>
						<div className='text-center text-muted mb-4'>
							<small>Verify OTP</small>
						</div>
						<Form role='form' onSubmit={handleSubmit}>
							<FormGroup>
								<InputGroup className='input-group-alternative mb-3'>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText>
											<i className='ni ni-hat-3' />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder='OTP'
										type='text'
										name='otp'
										value={formData.otp}
										onChange={handleChange}
										invalid={!!errors.otp}
									/>
									<div className='invalid-feedback'>
										{errors.otp}
									</div>
								</InputGroup>
							</FormGroup>

							<div className='text-center'>
								<Button
									className='mt-4'
									color='primary'
									type='submit'
									disabled={loader}
								>
									{loader ? (
										<Spinner size='sm' color='light' />
									) : (
										'Verify'
									)}
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default VerifyOTP;
