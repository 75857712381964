import { useState, useEffect } from "react";
import { IoIosClose, IoIosHelpCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Spinner } from "reactstrap";
import {
  addvanceInfoPersonality,
  getAdvancePersonalityAction,
} from "store/actions/avatarAction";

const Personality = () => {
  const location = useLocation();
  const {Id,title,imageUrl} = location?.state?.data;
  const { submitLoading, getAdvancePersonality } = useSelector(
    (state) => state?.create
  );
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdvancePersonalityAction(Id));
  }, []);

  const moodTypes = [
    { type1: "Sadness", type2: "Joy" },
    { type1: "Anger", type2: "Fear" },
    { type1: "Disgust", type2: "Trust" },
    { type1: "Anticipation", type2: "Surprise" },
    { type1: "Static emotions", type2: "Dynamic emotions" },
  ];

  const personalityTypes = [
    { type1: "Negative", type2: "Positive" },
    { type1: "Aggressive", type2: "Peaceful" },
    { type1: "Cautious", type2: "Open" },
    { type1: "Introvert", type2: "Extravert" },
    { type1: "Insecure", type2: "Confident" },
  ];

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [moodValues, setMoodValues] = useState(
    moodTypes.reduce((acc, { type1 }) => ({ ...acc, [type1]: 50 }), {})
  );
  const [personalityValues, setPersonalityValues] = useState(
    personalityTypes.reduce((acc, { type1 }) => ({ ...acc, [type1]: 50 }), {})
  );

  useEffect(() => {
    if (getAdvancePersonality) {
      const {
        CharacterTraits,
        MoodSadnessJoy,
        MoodAngerFear,
        MoodDisgustTrust,
        MoodAnticipationSurprise,
        MoodStaticDynamicEmotions,
        PersonalityNegativePositive,
        PersonalityAggressivePeaceful,
        PersonalityCautiousOpen,
        PersonalityIntrovertExtravert,
        PersonalityInsecureConfident,
      } = getAdvancePersonality;

      setTags(CharacterTraits?CharacterTraits?.split(' @#! '):[]);
      setMoodValues({
        Sadness: MoodSadnessJoy,
        Anger: MoodAngerFear,
        Disgust: MoodDisgustTrust,
        Anticipation: MoodAnticipationSurprise,
        ["Static emotions"]: MoodStaticDynamicEmotions,
      });

      setPersonalityValues({
        Negative: PersonalityNegativePositive,
        Aggressive: PersonalityAggressivePeaceful,
        Cautious: PersonalityCautiousOpen,
        Introvert: PersonalityIntrovertExtravert,
        Insecure: PersonalityInsecureConfident,
      });
    }
  }, [getAdvancePersonality]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "" && tags?.length < 10) {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleMoodChange = (type, value) => {
    setMoodValues({ ...moodValues, [type]: value });
  };

  const handlePersonalityChange = (type, value) => {
    setPersonalityValues({ ...personalityValues, [type]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const CharacterTraits = tags?.join(" @#! ");
    const { Anger, Sadness, Disgust, Anticipation } = moodValues;
    const Static_emotions = moodValues["Static emotions"];
    const { Negative, Cautious, Aggressive, Introvert, Insecure } = personalityValues;
    
    const payload = {
      AvatarId: Id,
      CharacterTraits: CharacterTraits,
      PersonalKnowledge: "",
      MoodSadnessJoy: Sadness,
      MoodAngerFear: Anger,
      MoodDisgustTrust: Disgust,
      MoodAnticipationSurprise: Anticipation,
      MoodStaticDynamicEmotions: Static_emotions,
      PersonalityNegativePositive: Negative,
      PersonalityAggressivePeaceful: Aggressive,
      PersonalityCautiousOpen: Cautious,
      PersonalityIntrovertExtravert: Introvert,
      PersonalityInsecureConfident: Insecure,
      isPersonalKnowledgePublic: false,
    };

    dispatch(addvanceInfoPersonality(payload,()=>{
      dispatch(getAdvancePersonalityAction(Id));
    }));
  };

  return (
    <div className="outerPersonality">
      <h1>Personality</h1>
      <div className="Outer-InputTrait">
        <p id="Character-Trait">
          Character Traits <IoIosHelpCircleOutline size={15} />
        </p>
        <div className="tag-container">
          {tags?.map((tag, index) => (
            <div key={index} className="tag">
              {tag}
              <IoIosClose
                size={15}
                className="close-Icon"
                onClick={() => handleTagRemove(tag)}
              />
            </div>
          ))}
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className="InputTrait"
          />
        </div>
      </div>
      {tags?.length === 10 && (
        <small>"Character Traits can't be more than 10"</small>
      )}
      <div className="character">
        <div className="Mood">
          <h1>Mood</h1>
          {moodTypes.map((item, index) => (
            <div key={index} className="innerMood">
              <ol>
                <li>{item.type1}</li>
                <li>{item.type2}</li>
              </ol>
              <input
                type="range"
                min="0"
                max="100"
                className="InputRange"
                value={moodValues[item.type1]}
                onChange={(e) =>
                  handleMoodChange(item.type1, parseInt(e.target.value))
                }
              />
            </div>
          ))}
        </div>
        <div className="Personality">
          <h1>Personality</h1>
          {personalityTypes.map((item, index) => (
            <div key={index} className="innerMood">
              <ol>
                <li>{item.type1}</li>
                <li>{item.type2}</li>
              </ol>
              <input
                type="range"
                min="0"
                max="100"
                className="InputRange"
                value={personalityValues[item.type1]}
                onChange={(e) =>
                  handlePersonalityChange(item.type1, parseInt(e.target.value))
                }
              />
            </div>
          ))}
        </div>
      </div>

      <Button
        className="my-4"
        color="primary"
        onClick={handleSubmit}
        disabled={submitLoading}
      >
       Submit {submitLoading && <Spinner size="sm" color="light" />}
      </Button>
    </div>
  );
};

export default Personality;
