import { RepositoryFactory } from "../../repository/RepositoryFactory";
import {
  FILE_UPLOAD,
  GET_AVATAR,
  UPDATE_PHOTO,
  CREATEAVATAR,
  GETALLAVATAR,
  GETUSERAVATAR,
  GET_AVATAR_Files,
  DELETE_AVATAR_FILES,
  GET_PERSONAL_KNOWLEDGE,
  GET_ADVANCE_PERSONALITY,
  GET_ADVANCE_INFO,
  SET_AVATAR_DATA,
  DELETE_AVATAR_FILES_LOADER,
  LOADER,
} from "store/types";
import { toast } from "react-toastify";
import { SIGNUP } from "store/types";
import { ANALYTICS } from "store/types";
import { ACTIVEUSERS } from "store/types";
import { USERLOADER } from "store/types";
import { ANALYTICSLOADER } from "store/types";
import { ACTIVEUSERLOADER } from "store/types";
import { DELETEAVATAR } from "store/types";

// Get the repository for creating avatars
const product = RepositoryFactory.get("Create");
export const setLoading = (val) => async (dispatch) => {
  dispatch({ type: FILE_UPLOAD, payload: val });
};
export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: LOADER, payload: val });
};
export const setActiveUserLoading = (val) => async (dispatch) => {
  dispatch({ type: ACTIVEUSERLOADER, payload: val });
};
export const setUserAvatarLoading = (val) => async (dispatch) => {
  dispatch({ type: USERLOADER, payload: val });
};
export const fileDeleteLoader = (val) => async (dispatch) => {
  dispatch({ type: DELETE_AVATAR_FILES_LOADER, payload: val });
};
// Action creator function
export const createAvatar = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    const { data } = await product.createAvatar(payload);
    if (data.status_code == 200) {
      onSuccess();
      dispatch({ type: CREATEAVATAR, payload: data.details });
      await dispatch(setAuthLoading(false));
    } else {
      toast.warning(data?.details);
      await dispatch(setAuthLoading(false));
    }
  } catch (error) {
    await dispatch(setAuthLoading(false));
    // Handle any errors here
    console.error("Error creating avatar:", error);
  }
};
export const uploadFileAction = (Id, files, onSuccess) => async (dispatch) => {
  await dispatch(setLoading(true));
  try {
    const { data } = await product.uploadFile(Id, files);
    if (data.status_code == 200) {
      onSuccess();
      toast.success("File Successfully Added");
      dispatch({ type: FILE_UPLOAD, payload: data.details });
      await dispatch(setLoading(false));
    } else {
      toast.warning(data?.details)
      await dispatch(setLoading(false));
    }
  } catch (error) {
    // Handle any errors here
    await dispatch(setLoading(false));
    console.error("Error creating avatar:", error);
  }
};
export const personalknowledgeAction = (payload) => async (dispatch) => {
  await dispatch(setLoading(true));
  try {
    const { data } = await product.personalknowledge(payload);
    if (data.status_code == 200) {
      toast.success("Info Updated Successfully");
      dispatch({ type: FILE_UPLOAD, payload: data.details });
      await dispatch(setLoading(false));
    } else {
      toast.warning(data?.details);
      await dispatch(setLoading(false));
    }
  } catch (error) {
    // Handle any errors here
    await dispatch(setLoading(false));
    console.error("Error creating avatar:", error);
  }
};
export const addvanceInfoPersonality =
  (payload, onSuccess) => async (dispatch) => {
    await dispatch(setLoading(true));
    try {
      const { data } = await product.advanceknowledge(payload);
      if (data.status_code == 200) {
        toast.success("Info Updated Successfully");
        onSuccess();
        await dispatch(setLoading(false));
      } else {
        toast.warning(data?.details);
        await dispatch(setLoading(false));
      }
    } catch (error) {
      // Handle any errors here
      await dispatch(setLoading(false));
      console.error("Error creating avatar:", error);
    }
  };

export const getAvatarDefaultDataAction = (Id) => async (dispatch) => {
  try {
    const { data } = await product.getAvatarDefaultData(Id);
    if (data.status_code == 200) {
      dispatch({ type: GET_AVATAR, payload: data.details });
    } else {
      toast.warning(data?.details);
    }
  } catch (error) {
    console.error("Error in avatar default data :", error);
  }
};
export const updateProfilePhotoAction =
  (Id, file, onSuccess) => async (dispatch) => {
    try {
      const { data } = await product.updateProfilePhoto(Id, file);
      if (data.status_code == 200) {
        onSuccess();
        // toast.success(data?.details)
        dispatch({ type: UPDATE_PHOTO, payload: data.details });
      } else {
        toast.warning(data?.details);
      }
    } catch (error) {
      console.error("Error in Update Profile:", error);
    }
  };

// Action getAvatar Function
export const getAvatar = (onSuccess) => async (dispatch) => {
  await dispatch(setUserAvatarLoading(true));
  try {
    // Make the API call to get the avatar
    const { data } = await product.getAvatar();

    if (data.status_code == 200) {
      dispatch({ type: GETUSERAVATAR, payload: data.details });
      // onSuccess();
      await dispatch(setUserAvatarLoading(false));
    } else if (data.status_code === 404) {
      await dispatch(setUserAvatarLoading(false));
    } else {
      await dispatch(setUserAvatarLoading(false));
      console.log("error in get user avatar", data?.details);
    }
  } catch (error) {
    // Handle any errors here
    await dispatch(setUserAvatarLoading(false));
    console.error("Error getting avatar:", error);
  }
};
export const getAllAvatar = (onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    // Make the API call to get  All the avatar
    const { data } = await product.getAllAvatar();

    if (data.status_code == 200) {
      dispatch({ type: GETALLAVATAR, payload: data.details });
      dispatch(setAuthLoading(false));
      // onSuccess();
    } else {
      dispatch(setAuthLoading(false));
    }
  } catch (error) {
    // Handle any errors here
    console.error("Error getting  All avatars:", error);
     dispatch(setAuthLoading(false));
  }
};

export const addBasicInfo = (payload, onSuccess) => async (dispatch) => {
  await dispatch(setLoading(true));
  try {
    const { data } = await product.addBasicInfo(payload);
    if (data.status_code == 200) {
      await dispatch(setLoading(false));
      onSuccess();
      // dispatch({ type: UPDATE_PHOTO, payload: data.details });
    } else {
      toast.warning(data?.details);
      await dispatch(setLoading(false));
    }
  } catch (error) {
    console.error("Error in add basic info:", error);
    await dispatch(setLoading(false));
  }
};

export const getAvatarFilesAction = (avatarId) => async (dispatch) => {
  try {
    const { data } = await product.getAvatarFiles(avatarId);
    if (data.status_code == 200) {
      dispatch({ type: GET_AVATAR_Files, payload: data.details });
    } else {
      dispatch({ type: GET_AVATAR_Files, payload: [] });
      console.log(data?.details);
      // toast.warning(data?.details);
    }
  } catch (error) {
    console.error("Error in getting avatar files:", error);
  }
};
export const deleteAvatarFiles = (payload, onSuccess) => async (dispatch) => {
  await dispatch(fileDeleteLoader(true));
  try {
    const { data } = await product.deleteAvatarFiles(payload);
    if (data.status_code == 200) {
      await dispatch(fileDeleteLoader(false));
      onSuccess();
      toast.success("File Successfully Deleted");
      dispatch({ type: DELETE_AVATAR_FILES, payload: data.details });
    } else {
      toast.warning(data?.details);
      await dispatch(fileDeleteLoader(false));
    }
  } catch (error) {
    console.error("Error getting in delete Files:", error);
    await dispatch(fileDeleteLoader(false));
  }
};
export const getPersonalknowledgeAction =
  (avatarId, onSuccess) => async (dispatch) => {
    try {
      const { data } = await product.getPersonalknowledge(avatarId);
      if (data.status_code == 200) {
        onSuccess();
        dispatch({
          type: GET_PERSONAL_KNOWLEDGE,
          payload: data?.details,
        });
      } else {
        toast.warning(data?.details);
      }
    } catch (error) {
      console.error("Error getting in Personal Knowledge:", error);
    }
  };
export const getAdvancePersonalityAction = (avatarId) => async (dispatch) => {
  dispatch({ type: GET_ADVANCE_PERSONALITY, payload: [] });
  try {
    const { data } = await product.getAdvancePersonality(avatarId);
    if (data.status_code == 200) {
      dispatch({ type: GET_ADVANCE_PERSONALITY, payload: data?.details });
    } else {
      console.warn(data?.details);
    }
  } catch (error) {
    console.error("Error getting in Personal Knowledge:", error);
  }
};
export const getBasicInfoAction = (avatarId) => async (dispatch) => {
  try {
    const { data } = await product.getBasicInfo(avatarId);
    if (data.status_code == 200) {
      dispatch({ type: GET_ADVANCE_INFO, payload: data?.details });
    } else {
      console.warn(data?.details);
    }
  } catch (error) {
    console.error("Error getting in Basic Info :", error);
  }
};
export const checkAdvanceInfo = (avatarId, onSuccess) => async (dispatch) => {
  try {
    const { data } = await product.checkAdvanceInfo(avatarId);
    if (data.status_code == 200) {
      onSuccess();
    } else {
      toast.warning("Avatar Advance Info Missing!");
    }
  } catch (error) {
    console.error("Error getting in Basic Info :", error);
  }
};

export const setAvatarData = (data) => async (dispatch) => {
  console.log("payload123", data);
  dispatch({ type: SET_AVATAR_DATA, payload: data });
};

export const CreateAvatarAction =
  (payload, onSuccess, onError) => async (dispatch) => {
    await dispatch(setAuthLoading(true));
    try {
      let { data } = await product.createAvatarRepo(payload);
      console.log("Signup Server Response", data);
      if (data?.status_code == 200) {
        dispatch({ type: SIGNUP, payload: payload });
        // toast.success(data?.detail)
        onSuccess(data);
      } else {
        toast.warning(data?.detail);
        onError(data.detail);
      }
    } catch (e) {
      console.error("Signup error:", e);
      onError(e.message || "An error occurred during signup.");
    } finally {
      await dispatch(setAuthLoading(false));
    }
  };
export const UserAnalyticsAction = () => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await product.userAnalyticsRepo();

    if (data?.status_code == 200) {
      dispatch({ type: ANALYTICS, payload: data?.details });
      await dispatch(setAuthLoading(false));
      // toast.success(data?.detail)
    } else {
      toast.warning(data?.details);
      await dispatch(setAuthLoading(false));
    }
  } catch (e) {
    console.error("Analytics error:", e);
  } finally {
    await dispatch(setAuthLoading(false));
  }
};
export const GetActiveUsersAction = (key) => async (dispatch) => {
  await dispatch(setActiveUserLoading(true));
  try {
    let { data } = await product.getActiveUsersRepo(key);

    if (data?.status_code == 200) {
      dispatch({ type: ACTIVEUSERS, payload: data?.details });
      // toast.success(data?.detail)
      dispatch(setActiveUserLoading(false));
    } else {
      toast.warning(data?.details);
      dispatch(setActiveUserLoading(false));
    }
  } catch (e) {
    await dispatch(setActiveUserLoading(false));
    console.error("Active users error:", e);
  } finally {
    await dispatch(setActiveUserLoading(false));
  }
};

export const deleteAvatarAction = (avatarId,onSuccess) => async (dispatch) => {
  await dispatch(setAuthLoading(true));
  try {
    let { data } = await product.deleteAvatarRepo(avatarId);

    if (data?.status_code == 200) {
      dispatch({ type: DELETEAVATAR, payload: data?.details });
      // toast.success(data?.detail)
      onSuccess();
      dispatch(setAuthLoading(false));
    } else {
      toast.warning(data?.details);
      dispatch(setAuthLoading(false));
    }
  } catch (e) {
    await dispatch(setAuthLoading(false));
    console.error("Delete User Avatar error:", e);
  } finally {
    await dispatch(setAuthLoading(false));
  }
};
