import axios from 'axios';
export const baseDomain = 'https://backend.trytsuki.com';
// export const baseDomain = 'https://steady-dassie-inspired.ngrok-free.app';
const baseURL = `${baseDomain}`;
// const getToken = () => {
//     let ls = JSON.parse(localStorage.getItem("authUser"));
//     console.log("ls", ls?.user?.AccessToken);
//     if (ls && ls?.user?.AccessToken != null) {
//         return ls?.user?.AccessToken;
//     } else {
//         return "";
//     }
// };

let axiosObj;
axiosObj = axios.create({
	baseURL,
	// headers: {
	//     token: getToken(),
	// },
});
axiosObj.interceptors.request.use(
	config => {
		// Dynamically set the token for each request
		const ls = JSON.parse(localStorage.getItem('authUser'));
		const token = ls?.token || '';
		config.headers.token = token;
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);
export default axiosObj;
