import React, { useState } from 'react';
import Text from './Text';
import Document from './Document';

const KnowCognito = () => {

 
 // Active Clicking and Switching
 const [active,setActive] =useState(2);
  const handlePer =()=>
  {
      setActive(1);
  }
  const handleKC =()=>
  {
      setActive(2);
  }

  return (
    <div className='outerPersonality'>
        <div className="order">
        <button onClick={()=>{handlePer()}} className={`${active === 1 ? "li": "libtn "}`} >Text</button>
            <button onClick={()=>{handleKC()}}  className={`${active === 2 ? "li": "libtn "}`} >Document</button>
            </div>
      {active === 1 ? <Text/> : <Document/>}    
    </div>
  );
};

export default KnowCognito;
