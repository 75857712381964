import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardImg, CardBody, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setAvatarData } from "store/actions/avatarAction";
import { checkAdvanceInfo } from "store/actions/avatarAction";
import DeleteModal from "./Modals/DeleteModal";
import { deleteAvatarAction } from "store/actions/avatarAction";
import { getAllAvatar } from "store/actions/avatarAction";
import { getAvatar } from "store/actions/avatarAction";

const CustomCard = ({ title, imageUrl, onChat, Id, type }) => {
  const { loading } = useSelector((state) => state?.create);
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleEdit = () => {
    const data = { title: title, imageUrl: imageUrl, Id: Id };
    dispatch(setAvatarData(data));

    history.push({
      pathname: `/admin/edit-avatar`,
      state: {
        data,
      },
    });
  };

  const handleChatClick = () => {
    dispatch(
      checkAdvanceInfo(Id, () => {
        onChat();
      })
    );
  };
  const handleDelete = (e, Id) => {
    e.preventDefault();
    setDeleteOpen(true);
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    dispatch(
        deleteAvatarAction(Id, () => {
            dispatch(getAllAvatar(Id));
			dispatch(getAvatar());
            setDeleteOpen(false);
        })
    );
  };
  const CloseModal = () => {
    setDeleteOpen(false);
  };
  return (
    <div className="custom-card">
      <Card
        style={{
          minHeight: "200px",
          backgroundImage: `url(${
            imageUrl != null
              ? imageUrl
              : require("../assets/img/avatar/avtar.placeholder.png").default
          })`,
          backgroundSize: "cover", // Ensures the image covers the entire card
          backgroundPosition: "center", // Centers the
          backgroundRepeat: "no-repeat",
        }}
        className=" rounded shadow-sm "
      >
        {/* <CardImg
          top
          width="100%"
          src={
            imageUrl != null
              ? imageUrl
              : require("../assets/img/avatar/avtar.placeholder.png").default
          }
          alt="Card image cap"
          className="card-image img-fluid"
        /> */}
        <CardBody className="text-center card-body-overlay">
          <div className="card-options">
            <Button color="info threeBtn" onClick={handleChatClick}>
              Chat
            </Button>
            {type == "avatar" ? (
              <>
                <Button color="primary threeBtn" onClick={() => handleEdit(Id)}>
                  Edit
                </Button>
                <Button
                  color="danger threeBtn"
                  onClick={(e) => handleDelete(e, Id)}
                >
                  Delete
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        </CardBody>
      </Card>
      <h3 className="ml-2 mt-2">{title}</h3>
      {deleteOpen && (
        <DeleteModal
          DeleteHandler={confirmDelete}
          loading={loading}
          closeModal={CloseModal}
        />
      )}
    </div>
  );
};

export default CustomCard;
