import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllAvatar } from "store/actions/avatarAction";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import CustomCard from "components/CustomCard";
import { baseDomain } from "repository/Repository";
import AvatarSkelton from "components/Skelton/AvatarSkelton";
import ChatModal from "views/chatModal";
import DeleteModal from "components/Modals/DeleteModal";

const AllAvatar = () => {
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [activeAvatar, setActiveAvatar] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const dispatch = useDispatch();
  const { allAvatar, loading } = useSelector((state) => state?.create);
  const { user } = useSelector((state) => state?.authUser);

  const handleChat = (avatar) => {
    setActiveAvatar(avatar); // Set the active avatar to the one being chatted with
    setIsChatModalOpen(true); // Open the chat modal
  };
  useEffect(() => {
    dispatch(getAllAvatar());
  }, [dispatch]);


 
  return (
    <>
      <Row>
        {allAvatar.length > 0 ? (
          allAvatar?.map((avatar, index) => {
            return (
              <Col md="3" className="mt-4" key={index}>
                <CustomCard
                  title={avatar.Name || "My Card"}
                  imageUrl={
                    avatar?.ProfilePhoto
                      ? `${baseDomain}/get-file?filename=${avatar?.ProfilePhoto}`
                      : null
                  }
                  Id={avatar?.Id}
                  onChat={() => handleChat(avatar)}
                  type="avatar"
                  // description=
                />
              </Col>
            );
          })
        ) : loading ? (
          [...Array(3)].map((_, index) => <AvatarSkelton key={index} />)
        ) : (
          <span></span>
        )}
      </Row>
      <ChatModal
        audioUrl={audioUrl}
        setAudioUrl={setAudioUrl}
        toggle={() => setIsChatModalOpen(false)}
        isOpen={isChatModalOpen}
        avatar={activeAvatar}
      />
    
    </>
  );
};

export default AllAvatar;
