// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import createReducer from "./createReducer";

export let rootReducer = combineReducers({
  authUser: authUserReducer,
  create:createReducer
 
 
});

export default rootReducer;
