import {
  CREATEAVATAR,
  FILE_UPLOAD,
  GET_AVATAR,
  GETUSERAVATAR,
  GETALLAVATAR,
  GET_AVATAR_Files,
  DELETE_AVATAR_FILES,
  GET_PERSONAL_KNOWLEDGE,
  GET_ADVANCE_PERSONALITY,
  GET_ADVANCE_INFO,
  SET_AVATAR_DATA,
  DELETE_AVATAR_FILES_LOADER,
  LOADER,
  ANALYTICS,
  ACTIVEUSERS,
  USERLOADER,
  ACTIVEUSERLOADER,
} from "store/types";
const initialState = {
  data: [],
  avatar: [],
  allAvatar: [],
  userAvatar: [],
  avatarFiles: [],
  loading: false,
  activeUserLoader:false,
  userLoading:false,
  submitLoading: false,
  deleteLoader: false,
  getPersonalKnowledg: [],
  getAdvancePersonality:[],
  getBasicInfo:[],
  avatarData: {},
  analytics:[],
  activeUsers:[],
};

export default function createReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CREATEAVATAR:
      return {
        ...state,
        data: [...state.data, payload],
      };
    case FILE_UPLOAD: {
      return {
        ...state,
        submitLoading: payload,
      };
    }
    case GETALLAVATAR: {
      return {
        ...state,
        allAvatar: payload,
      };
    }
    case LOADER: {
      return {
        ...state,
        loading:payload,
      };
    }
    case USERLOADER: {
      return {
        ...state,
        userLoading:payload,
      };
    }
    case GET_AVATAR: {
      return {
        ...state,
        avatar: payload,
      };
    }
    case GETUSERAVATAR:
      return {
        ...state,
        userAvatar: payload,
      };
    case ACTIVEUSERLOADER:
      return {
        ...state,
        activeUserLoader: payload,
      };
    case GET_AVATAR_Files:
      return {
        ...state,
        avatarFiles: payload,
      };
    case DELETE_AVATAR_FILES_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case GET_PERSONAL_KNOWLEDGE:
      return {
        ...state,
        getPersonalKnowledg: payload,
      };
    case GET_ADVANCE_PERSONALITY:
      return {
        ...state,
        getAdvancePersonality: payload,
      };
    case GET_ADVANCE_INFO:
      return {
        ...state,
        getBasicInfo: payload,
      };
      case SET_AVATAR_DATA:
        return {
          ...state,
          avatarData: payload,
        };
      case ANALYTICS:
        return {
          ...state,
          analytics: payload,
        };
      case ACTIVEUSERS:
        return {
          ...state,
          activeUsers: payload,
        };
    default:
      return { ...state };
  }
}
