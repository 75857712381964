import Repository from './Repository';
const CREATEAVATAR = 'AvatarSetup/AddProfileData';
const GETAVATAR = '/AvatarSetup/GetUserAvatars';

const GETALLAVATAR = '/AvatarSetup/GetAllAvatars';
const UPLOADFILE = 'AvatarSetup/AddQueryFile';
const PERSNOLKNOWLEDGE = '/AvatarSetup/AddAdvanceInfo';
const GETAVATARDEFAULTDATA = 'AvatarSetup/GetAvatarSetting';
const UPDATEPROFILEPHOTO = '/AvatarSetup/AddProfilePhoto';
const ADDBASICINFO = '/AvatarSetup/AddBasicInfo';
const GETAVATARFILES = '/AvatarSetup/GetAllFiles';
const DELETEAVATARFILES = '/AvatarSetup/DeleteFile';
const GETPERSONALKNOWLEDGE = '/AvatarSetup/GetPersonalKnowledge';
const GETADVANCEPERSONALITY = '/AvatarSetup/GetAdvanceInfo';
const GETBASICINFO = '/AvatarSetup/GetBasicInfo';
const CHECKADVANCEINFO = '/AvatarSetup/Check-Advance-Info';
const CREATEUSERAVATAR = '/Auth/User-SignUp';
const USERANALYTICS = '/Analytics/GetAnalytics';
const GETACTIVEUSERS = 'Analytics/GetActiveUsers';
const DELETEAVATAR = '/AvatarSetup/DeleteAvatar';

const AvatarRepositry = {
	createAvatar(payload) {
		return Repository.post(`${CREATEAVATAR}`, payload);
	},
	getAvatar() {
		return Repository.get(`${GETAVATAR}`);//get
	},
	getAllAvatar() {
		return Repository.get(`${GETALLAVATAR}`);//get
	},
	uploadFile(Id, files) {
		return Repository.post(`${UPLOADFILE}?AvatarId=${Id}`, files);
	},
	personalknowledge(payload) {
		return Repository.post(`${PERSNOLKNOWLEDGE}`, payload);
	},
	advanceknowledge(payload) {
		return Repository.post(`${PERSNOLKNOWLEDGE}`, payload);
	},
	getAvatarDefaultData(Id) {
		return Repository.get(`${GETAVATARDEFAULTDATA}?AvatarId=${Id}`);
	},
	updateProfilePhoto(Id, file) {
		return Repository.put(`${UPDATEPROFILEPHOTO}?AvatarId=${Id}`, file);
	},
	addBasicInfo(payload) {
		return Repository.post(`${ADDBASICINFO}`, payload);
	},
	getAvatarFiles(AvatarId) {
		return Repository.get(`${GETAVATARFILES}?AvatarId=${AvatarId}`,);//get
	},
	deleteAvatarFiles(payload) {
		return Repository.post(`${DELETEAVATARFILES}`, payload);
	},
	getPersonalknowledge(avatarId) {
		return Repository.get(`${GETPERSONALKNOWLEDGE}?AvatarId=${avatarId}`);//get
	},
	getAdvancePersonality(avatarId) {
		return Repository.get(`${GETADVANCEPERSONALITY}?AvatarId=${avatarId}`);//get
	},
	getBasicInfo(avatarId) {
		return Repository.get(`${GETBASICINFO}?AvatarId=${avatarId}`);//get
	},
	checkAdvanceInfo(avatarId) {
		return Repository.post(`${CHECKADVANCEINFO}?AvatarId=${avatarId}`);
	},
	createAvatarRepo(payload) {
		return Repository.post(`${CREATEUSERAVATAR}`,payload);
	},
	userAnalyticsRepo() {
		return Repository.get(`${USERANALYTICS}`);
	},
	getActiveUsersRepo(key) {
		return Repository.get(`${GETACTIVEUSERS}?key=${key}`);
	},
	deleteAvatarRepo(avatarId) {
		return Repository.post(`${DELETEAVATAR}?AvatarId=${avatarId}`);
	},
};
export default AvatarRepositry;
