import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllAvatar } from "store/actions/avatarAction";
import avtarplaceholder from "../../src/assets/img/avatar/avtar.placeholder.png";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import CustomCard from "components/CustomCard";
import OnlyHeader from "components/Headers/OnlyHeader";
import { baseDomain } from "repository/Repository";
import AvatarSkelton from "components/Skelton/AvatarSkelton";
import ChatModal from "./chatModal";
import Skeleton from "react-loading-skeleton";
import AnalyticsDetails from "components/Analytics/AnalyticsDetails";
import AllAvatar from "components/AllAvatar/AllAvatar";
const Dashboard = () => {
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [activeAvatar, setActiveAvatar] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const dispatch = useDispatch();
  const { allAvatar, loading } = useSelector((state) => state?.create);
  const { user } = useSelector((state) => state?.authUser);

  const handleChat = (avatar) => {
    setActiveAvatar(avatar); // Set the active avatar to the one being chatted with
    setIsChatModalOpen(true); // Open the chat modal
  };
  useEffect(() => {
      dispatch(getAllAvatar());
  }, [dispatch]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Avatar</h3>
              </CardHeader>
              <CardBody>
                <AllAvatar/>
                {/* <Row>
                  {allAvatar.length > 0 ? (
                    allAvatar?.map((avatar, index) => {
                      return (
                        <Col md="3" className="mt-4" key={index}>
                          <CustomCard
                            title={avatar.Name || "My Card"}
                            imageUrl={
                              avatar?.ProfilePhoto
                                ? `${baseDomain}/get-file?filename=${avatar?.ProfilePhoto}`
                                : null
                            }
                            Id={avatar?.Id}
                            onChat={() => handleChat(avatar)}
                            type="avatar"
                            // description=
                          />
                        </Col>
                      );
                    })
                  ) : loading ? (
                    [...Array(3)].map((_, index) => (
                      <AvatarSkelton key={index} />
                    ))
                  ) : (
                    <span></span>
                  )}
                </Row> */}
              </CardBody>
            </Card>
          </div>
        </Row>
        {/* <ChatModal
          audioUrl={audioUrl}
          setAudioUrl={setAudioUrl}
          toggle={() => setIsChatModalOpen(false)}
          isOpen={isChatModalOpen}
          avatar={activeAvatar}
        /> */}
      </Container>
      <Container>
      <AnalyticsDetails/>
      </Container>
     
    
    </>
  );
};

export default Dashboard;
