import authRepository from "./authRepository";
import AvatarRespository from "./AvatarRespository";
const repositories = {
  auth: authRepository,
  Create: AvatarRespository
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
