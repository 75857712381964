import Skeleton from "react-loading-skeleton";
import { Card, CardBody } from "reactstrap";
import "react-loading-skeleton/dist/skeleton.css";
const AvatarSkelton = () => {
  return (
    <>
      <div
        style={{ height: "205px", width:"200px"}}
        className="rounded text-center p-2 m-2  border "
      >
        <Skeleton style={{ height: "110px",width:"110px" }}  className="  rounded-circle" />

        <Skeleton  style={{ height: "60px" }} className="mt-3" />
      </div>
    </>
  );
};

export default AvatarSkelton;
