import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import Swal from "sweetalert2";

// reactstrap components

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader";
import { useDispatch, useSelector } from "react-redux";
import { createAvatar } from "store/actions/avatarAction";
import CustomCard from "components/CustomCard";
import ChatModal from "./chatModal";
import { getAvatar } from "store/actions/avatarAction";
import { baseDomain } from "repository/Repository";
import CreateAvatarModal from "components/Modals/CreateAvatar";
import AvatarSkelton from "components/Skelton/AvatarSkelton";
import AllAvatar from "components/AllAvatar/AllAvatar";
const Icons = () => {
  const { userAvatar,userLoading,allAvatar } = useSelector((state) => state.create);
  
  const { token, user } = useSelector((state) => state?.authUser);

  const [avatarData, setAvatarData] = useState(null);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [activeAvatar, setActiveAvatar] = useState(null);
  const [avatarCreated, setAvatarCreated] = useState(false);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [open, setOpen] = useState(false);

  //   const displayImage = async (filename) => {
  //     const baseUrl = `${baseDomain}/get-file`;
  //     const urlWithFilename = `${baseUrl}?filename=${encodeURIComponent(filename)}`;
  //     try {
  //         const response = await fetch(urlWithFilename, {
  //             method: 'GET',
  //             headers: {
  //                 'Accept': 'application/json', // Specify the expected response format
  //                 'token': token, // Include authentication token if required
  //             },
  //         });
  //         if (response.ok) {
  //           console.log('🚀 ~ getFile ~ response:', response.url);
  //           return response.url;
  //         }

  //     } catch (error) {
  //         console.error('There was a problem with your fetch operation:', error);
  //     }
  // };

  const toggleModal = () => {
    setOpen(!open);
    // setModalOpen(!modalOpen);
    // if (!modalOpen) {
    //   setName("");
    //   setDescription("");
    // }
  };
  const handleAvatarCreation = (avatar) => {
    setAvatarData(avatar); // Set the avatar data in state
  };
  const handleChat = (avatar) => {
    setActiveAvatar(avatar); // Set the active avatar to the one being chatted with
    setIsChatModalOpen(true); // Open the chat modal
  };

  const JSalert = () => {
    Swal.fire({
      title: "Congrats!",
      text: "Avatar created sucessfully!",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      Name: name,
      CoreDescription: description,
    };

    dispatch(
      createAvatar(payload, () => {
        JSalert();
        setName("");
        setDescription("");
        setModalOpen(false);
        setAvatarCreated(true); // Set avatarCreated state to true on successful creation
        dispatch(getAvatar());
      })
    );
  };

  useEffect(() => {
    dispatch(getAvatar());
  }, [user?.IsAdmin]);
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Avatar</h3>
                {user?.IsAdmin&& (
                  <Button
                    className="rounded-pill"
                    color="primary"
                    onClick={toggleModal}
                  >
                    <FaPlus style={{ marginRight: "0.5rem" }} />
                    Create Avatar
                  </Button>
                  )}
              </CardHeader>
              {user?.IsAdmin ===false ? (
              <CardBody>
                <Row>
                  {userAvatar?.length > 0 && (
                  userAvatar?.map((ele, index) => {
                    return (
                      <Col  md="3" key={index}>
                        <CustomCard
                          title={ele.Name}
                          imageUrl={
                            ele?.ProfilePhoto != null
                              ? `${baseDomain}/get-file?filename=${ele?.ProfilePhoto}`
                              : null
                          }
                          onChat={() => handleChat(ele)}
                          Id={ele?.Id}
                          type="avatar"
                        />
                      </Col>
                    );
                  }))}
                </Row>

              </CardBody>
              ):(
                <Container>
                <AllAvatar/>
                </Container>
              )}
            </Card>
          </Col>
        </Row>
        <ChatModal
          audioUrl={audioUrl}
          setAudioUrl={setAudioUrl}
          isOpen={isChatModalOpen}
          toggle={() => setIsChatModalOpen(false)}
          avatar={activeAvatar}
        />
        {open && <CreateAvatarModal open={open} setOpen={toggleModal} />}
        {/* Conditionally render the CustomCard component if avatar is created */}
      </Container>
    </>
  );
};

export default Icons;
