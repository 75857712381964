import Icons from "views/Avatar.js";
import Index from "views/Dashboard.js";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import EditAvatar from "components/EditAvatar/EditAvatar";
import VerifyOTP from "views/auth/VerifyOtp";
import Analytics from "views/Analytics";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/avatars",
    name: "Avatar",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "ni ni-chart-pie-35 text-blue",
    component: Analytics,
    layout: "/admin",
  },
  // Existing routes
  {
    path: "/edit-avatar",
    // name: "Edit Avatar",
    // icon: "ni ni-settings-gear-65 text-primary",
    component: EditAvatar,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/verifyotp",
    name: "Verify",
    icon: "ni ni-circle-08 text-pink",
    component: VerifyOTP,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
