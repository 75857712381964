import { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Spinner,
} from "reactstrap";
import {
  FaShareAlt,
  FaCog,
  FaGamepad,
  FaVolumeUp,
  FaRedoAlt,
  FaMicrophone,
  FaEllipsisH,
  FaTimes,
  FaVolumeMute,
} from "react-icons/fa"; // Import React Icons

import { MdCopyAll } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { useSelector } from "react-redux";
import { baseDomain } from "repository/Repository";
import axios from "axios";
import AudioRecorder from "components/AudioRecorder";
import { toast } from "react-toastify";

const ChatModal = ({ audioUrl, setAudioUrl, isOpen, toggle, avatar }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [chatData, setChatData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState(-1);
  const { token } = useSelector((state) => state.authUser);
  const messagesEndRef = useRef(null);
  const chatIdRef = useRef(chatId);
  const [isMuted, setIsMuted] = useState(false);
  const [audioBlobUrl, setAudioBlobUrl] = useState("");
  const audioRef = useRef(null);
  const [record, setRecord] = useState(false);
  const [transcriptionMessage, setTranscriptionMessage] = useState("");
  const now = new Date();
  // Format the date and time
  const formattedDate =
    now.getFullYear() +
    "-" +
    ("0" + (now.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + now.getDate()).slice(-2) +
    " " +
    ("0" + now.getHours()).slice(-2) +
    ":" +
    ("0" + now.getMinutes()).slice(-2) +
    ":" +
    ("0" + now.getSeconds()).slice(-2);
  //Drop Down State and Handle Change

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedScene, setSelectedScene] = useState("Text To Text");
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState); // Toggle the dropdown's open state
    setInputMessage(""); // Clear the message input field
    setTranscriptionMessage(""); // Clear the transcription message field
    setAudioUrl("");
  };
  const handleSelect = (scene) => {
    setSelectedScene(scene);
  };
  const handleCloseModal = (scene) => {
    setChatId(-1);
    setChatData([]);
    toggle();
    setAudioUrl("");
  };
  // useEffect(() => {
  // 	// Check if there's a new URL and an audio element to play it
  // 	if (audioUrl && audioRef.current) {
  // 		audioRef.current.src = `${baseDomain}/get-file?filename=${audioUrl}`;
  // 		audioRef.current
  // 			.play()
  // 			.catch(error => console.error('Playback failed:', error));
  // 	}
  // }, [audioUrl]);
  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.src = `${baseDomain}/get-file?filename=${audioUrl}`;
      if (!audioRef.current.muted) {
        audioRef.current
          .play()
          .catch((error) => console.error("Playback failed:", error));
      }
    }
  }, [audioUrl]);

  // Toggle recording
  const toggleRecording = () => {
    setInputMessage("");
    setAudioUrl("");
    setRecord((prev) => !prev);
  };

  // Handle the audio data after recording stops
  const onData = (recordedBlob) => {
    // console.log('chunk of real-time data is: ', recordedBlob);
  };

  // Handle the stop event and the final audio blob
  const onStop = async (recordedBlob) => {
    const audioFile = new File([recordedBlob.blob], "audio.webm", {
      type: recordedBlob.blob.type,
    });
    const transcription = await sendAudioToAPI(audioFile);
    setTranscriptionMessage(transcription.text);
    const url = URL.createObjectURL(recordedBlob.blob);
    setAudioBlobUrl(url);
  };

  const sendAudioToAPI = async (audioFile) => {
    const formData = new FormData();
    formData.append("file", audioFile);
    formData.append("model", "whisper-1"); // Assuming the API allows specifying the model here

    try {
      const response = await axios({
        method: "post",
        url: "https://api.openai.com/v1/audio/transcriptions",
        data: formData,
        headers: {
          Authorization: `Bearer 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`,
          // 'Content-Type' is removed to let Axios set it
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error sending audio to API:",
        error.response ? error.response.data : error
      );
      return null;
    }
  };

  const handleChange = (e) => {
    setTranscriptionMessage("");
    setInputMessage(e.target.value);
  };

  const toggleMute = () => {
    setIsMuted((prevMuteState) => {
      const newMuteState = !prevMuteState;
      if (audioRef.current) {
        audioRef.current.muted = newMuteState;
      }
      return newMuteState;
    });
  };

  const getChatbotMessage = async (payload, onDataReceived) => {
    try {
      const url = `${baseDomain}/AvatarSetup/Chat`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response !== null) {
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error("Response body reader is null");
        }
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          const chunk = new TextDecoder().decode(value);
          // Call the callback function with each chunk
          onDataReceived(chunk);
        }
      }
    } catch (error) {
      // Assuming you have error handling logic
      //console.error("There was a problem with your fetch operation:", error.message);
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  const getChatbotVoiceMessage = async (payload) => {
    const url = `${baseDomain}/AvatarSetup/TTSChat`;
    try {
      const response = await axios.post(url, payload, {
        // The data should be the second argument directly
        headers: {
          "Content-Type": "application/json",
          token: token, // Assuming you need to pass the token as a Bearer token
        },
      });
      if (response.data.status_code == 200) {
        let accumulatedAiText = "";
        accumulatedAiText = response?.data.details.Data;
        if (accumulatedAiText.includes("!@#$")) {
          accumulatedAiText = response.data.details.Data.split("!@#$")[1];
        }
        setChatData((prevChatData) => {
          const lastIndex = prevChatData.length - 1;
          const updatedChatData = [...prevChatData];
          updatedChatData[lastIndex] = {
            ...updatedChatData[lastIndex],
            User: updatedChatData[lastIndex].User,
            AI: accumulatedAiText,
          };
          return updatedChatData;
        });
        setAudioUrl(response.data.details.AudioFilePath);
      } else {
        console.error("Invalid data structure:", response.data.details);
        toast.error("Received malformed data. Please contact support.");
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };
  let accumulatedAiText = "";

  const updateChatWindow = (chunk) => {
    accumulatedAiText += chunk;

    if (accumulatedAiText.includes("!@#$")) {
      accumulatedAiText = accumulatedAiText.split("!@#$")[1];
    }
    if (accumulatedAiText.includes("1#$@!,")) {
      const parts = accumulatedAiText.split("1#$@!,");
      accumulatedAiText = parts[0]; // Assume the message is before the ID
      const id = parts[1]; // Assume the ID is after the split token

      if (id) {
        chatIdRef.current = id;
        setChatId(parseInt(id, 10));
      }
    }
    setChatData((prevChatData) => {
      const lastIndex = prevChatData.length - 1;
      const updatedChatData = [...prevChatData];
      const updatedLastItem = {
        ...updatedChatData[lastIndex],
        User: updatedChatData[lastIndex].User,
        AI: accumulatedAiText,
      };
      updatedChatData[lastIndex] = updatedLastItem;
      return updatedChatData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Get the current date and time

    if (inputMessage) {
      setInputMessage("");
      const payload = {
        // History: chatData,
        AvatarId: avatar?.Id,
        ChatId: Number(chatId),
        Query: inputMessage,
        DateTime: formattedDate,
      };
      console.log("payload", payload);
      if (selectedScene == 'Text To Text') {
      	try {
      		setChatData(prevChatData => [
      			...prevChatData,
      			{
      				User: inputMessage,
      				AI: '',
      			},
      		]);
      		setLoading(true);

      		await getChatbotMessage(payload, updateChatWindow);
      		setLoading(false);
      	} catch (error) {
      		setLoading(false);
      		console.error(
      			'Error in processing the request:',
      			error.message
      		);
      	}
      } else if (selectedScene == 'Text To Speech') {
      	try {
      		setChatData(prevChatData => [
      			...prevChatData,
      			{
      				User: inputMessage,
      				AI: '',
      			},
      		]);
      		setLoading(true);

      		await getChatbotVoiceMessage(payload);

      		setLoading(false);
      	} catch (error) {
      		setLoading(false);
      		console.error(
      			'Error in processing the request:',
      			error.message
      		);
      	}
      }
    } else {
      toast.warning("Your message is empty. Type something to send.");
    }
  };

  const handleSubmitVoice = async (e) => {
    // e.preventDefault();
    setAudioBlobUrl("");
    setAudioUrl("");
    if (transcriptionMessage) {
      const payload = {
        // History: chatData,
        AvatarId: avatar?.Id,
        ChatId: Number(chatId),
        Query: transcriptionMessage,
        DateTime: formattedDate,
      };
      if (selectedScene == "Speech To Speech") {
        try {
          setChatData((prevChatData) => [
            ...prevChatData,
            {
              User: transcriptionMessage,
              AI: "",
            },
          ]);
          setLoading(true);

          await getChatbotVoiceMessage(payload);

          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error in processing the request:", error.message);
        }
        setTranscriptionMessage("");
      } else if (selectedScene == "Speech To Text") {
        try {
          setChatData((prevChatData) => [
            ...prevChatData,
            {
              User: transcriptionMessage,
              AI: "",
            },
          ]);
          setLoading(true);

          await getChatbotMessage(payload, updateChatWindow);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error in processing the request:", error.message);
        }
        setTranscriptionMessage("");
      }
    } else {
      toast.warning("No recording found.Please record your voice.");
    }
  };

  useEffect(() => {
    chatIdRef.current = chatId;
  }, [chatId]);

  const defaultAvatar =
    "https://storage.googleapis.com/inworld-ai-usercontent-prod/2d_image/cf87f62e-1b28-41a2-83e1-1dcc1df6817a-original.jpg";

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  return (
    <Modal
      className="chat-modal"
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      centered
    >
      {/* <ModalHeader className='pb-0' toggle={toggle}></ModalHeader> */}
      <div className="d-flex justify-content-between align-items-center title">
        <div className="d-flex align-items-center">
          <img
            src={
              avatar?.ProfilePhoto != null
                ? `${baseDomain}/get-file?filename=${avatar?.ProfilePhoto}`
                : require("../assets/img/avatar/avtar.placeholder.png").default
            }
            alt=".."
            className="rounded-circle mr-2"
            style={{ width: "40px", height: "40px" }}
          />
          <h5 className="mb-0">{avatar?.Name || "Default Name"}</h5>
        </div>
        <div>
          <FaShareAlt style={{ margin: "0 10px" }} />
          <FaCog style={{ margin: "0 10px" }} />
          <FaGamepad style={{ margin: "0 10px" }} />
          <FaTimes
            onClick={handleCloseModal}
            style={{
              margin: "0 10px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      {/* <hr className='m-0' /> */}

      <ModalBody className="p-0">
        <div className="d-flex w-100 bg-lightgrey w-100 mr-0  justify-content-end py-1">
          <Dropdown
            className="mr-0"
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
          >
            <DropdownToggle caret>{selectedScene}</DropdownToggle>
            <DropdownMenu left>
              <DropdownItem
                onClick={() => handleSelect("Text To Text")}
                active={selectedScene === "Text To Text"}
              >
                Text To Text
              </DropdownItem>
              <DropdownItem
                onClick={() => handleSelect("Speech To Text")}
                active={selectedScene === "Speech To Text"}
              >
                Speech To Text
              </DropdownItem>
              <DropdownItem
                onClick={() => handleSelect("Speech To Speech")}
                active={selectedScene === "Speech To Speech"}
              >
                Speech To Speech
              </DropdownItem>
              <DropdownItem
                onClick={() => handleSelect("Text To Speech")}
                active={selectedScene === "Text To Speech"}
              >
                Text To Speech
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="chat-body">
          <div className="msgInner">
            <div className="msgMain">
              {chatData?.length > 0 &&
                chatData.map((ele, index) => {
                  return (
                    <>
                      {/* Send Message Code */}
                      <div className="sendChatOuter" key={index}>
                        <div className="send-msg">
                          <p className="send-msg-texts">{ele?.User}</p>
                        </div>
                        <div className="ml-1">
                          <img
                            className="msgUserImgsender"
                            src={
                              ele?.ProfilePhoto != null
                                ? `${baseDomain}/get-file?filename=${ele?.ProfilePhoto}`
                                : require("../assets/img/avatar/avtar.placeholder.png")
                                    .default
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      {/* Receive Message Code */}
                      <div className="receiveChatOuter">
                        {ele?.AI ? (
                          <div>
                            <img
                              className="msgUserImg"
                              src={
                                avatar?.ProfilePhoto != null
                                  ? `${baseDomain}/get-file?filename=${avatar?.ProfilePhoto}`
                                  : defaultAvatar
                              }
                              alt=""
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="Recieve-msg">
                          <p className="Recieve-msg-texts">
                            <p className="send-msg-texts mb-0">{ele?.AI}</p>
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div ref={messagesEndRef} />
          </div>
        </div>
      </ModalBody>
      <hr className="m-0" />
      <ModalFooter className="d-flex justify-content-between align-items-center chat-modal-footer px-3">
        <div className="w-100 p-2 bg-lightgrey rounded m-0">
          {selectedScene == "Text To Text" ||
          selectedScene == "Text To Speech" ? (
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  id="messageInput"
                  value={inputMessage}
                  onChange={handleChange}
                  disabled={record === true || loading == true}
                  type="text"
                  placeholder="Type your message..."
                  className="form-control"
                />
                <div
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                >
                  <button
                    type="submit"
                    className="input-group-text bg-lightblue"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      <IoMdSend className="icon-button text-white" />
                    )}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <>
              <AudioRecorder record={record} onStop={onStop} onData={onData} />
              {audioBlobUrl && (
                <div className="mt-2">
                  <audio src={audioBlobUrl} controls="controls" />
                </div>
              )}
              <div className="d-flex justify-content-between mt-2">
                <div className="icon-bar">
                  {isMuted ? (
                    <FaVolumeMute
                      onClick={toggleMute}
                      className="icon-button"
                      style={{
                        margin: "0 10px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <FaVolumeUp
                      onClick={toggleMute}
                      className="icon-button"
                      style={{
                        margin: "0 10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {/* <FaRedoAlt
									className='icon-button'
									style={{
										margin: '0 10px',
										cursor: 'pointer',
									}}
								/>
								<MdCopyAll
									className='icon-button'
									style={{
										margin: '0 10px',
										cursor: 'pointer',
									}}
								/>
								<FaEllipsisH
									className='icon-button '
									style={{
										margin: '0 10px',
										cursor: 'pointer',
									}}
								/> */}

                  {record ? (
                    <FaMicrophone
                      onClick={toggleRecording}
                      style={{
                        color: "#a60808",
                        cursor: "pointer",
                        margin: "0 10px",
                      }}
                    />
                  ) : (
                    <FaMicrophone
                      onClick={toggleRecording}
                      className="icon-button"
                      style={{
                        margin: "0 10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
                <div
                  onClick={loading == false ? () => handleSubmitVoice() : ""}
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text bg-lightblue">
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      <IoMdSend
                        className="icon-button text-white"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
          {audioUrl && (
            <>
              <audio
                ref={audioRef}
                style={{ display: "none" }}
                src={`${baseDomain}/get-file?filename=${audioUrl}`}
                controls
                autoPlay
              />
            </>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ChatModal;
