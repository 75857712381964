import Repository from "./Repository";
const SIGNUP = "/Auth/User-SignUp";
const LOGIN = "/Auth/LogIn"
const VERIFY = "/Auth/Verify-SignUp-OTP";
const UPDATE_PASSWORD = "/Doctor/Update-Password";


export default {
  UpdatePasswordRep(payload) {
    return Repository.put(`${UPDATE_PASSWORD}`, payload);
  },
  signuprep(payload) {
    return Repository.post(`${SIGNUP}`, payload);
  },
  loginrep(payload) {
    return Repository.post(`${LOGIN}`, payload);
  },
  verifyrep(payload) {
    return Repository.post(
      `${VERIFY}`,
      payload
    );
  },
};
