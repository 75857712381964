import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetActiveUsersAction } from "store/actions/avatarAction";
import { useSelector, useDispatch } from "react-redux";
// Example data
const monthlyData = [
  { name: "May", performance: 10 },
  { name: "Jun", performance: 30 },
  { name: "Jul", performance: 15 },
  { name: "Aug", performance: 40 },
  { name: "Sep", performance: 20 },
  { name: "Oct", performance: 40 },
  { name: "Nov", performance: 20 },
  { name: "Dec", performance: 50 },
];

const weeklyData = [
  { name: "Week 1", performance: 10 },
  { name: "Week 2", performance: 20 },
  { name: "Week 3", performance: 15 },
  { name: "Week 4", performance: 25 },
  { name: "Week 5", performance: 20 },
  { name: "Week 6", performance: 30 },
  { name: "Week 7", performance: 35 },
  { name: "Week 8", performance: 40 },
];

const LineChartComp = () => {
  const dispatch = useDispatch();
  const { activeUsers,activeUserLoader } = useSelector((state) => state?.create);

  const [data, setData] = useState(monthlyData);
  const [viewMode, setViewMode] = useState("Monthly");
  useEffect(() => {
    dispatch(GetActiveUsersAction(viewMode));
  }, []);
  const handleViewChange = (mode) => {
    setViewMode(mode);
       dispatch(GetActiveUsersAction(mode));
    // if(mode ==="Monthly"){
    // }else{
    //   dispatch(GetActiveUsersAction(mode));

    // }
    // setData(mode === "monthly" ? monthlyData : weeklyData);
  };

  return (
    <div
      style={{ backgroundColor: "#0f1b2b" }}
      className="p-4 rounded text-white"
    >
      <div className="d-flex justify-content-between">
        <div className="text-left">
          <h2 className="text-muted">User</h2>
          <p className="font-weight-bold">Active User</p>
        </div>
        <div>
          <button
            className={`btn btn-sm ${
              viewMode === "Monthly"
                ? "bg-primary text-white"
                : "bg-white text-primary"
            }`}
            onClick={() => handleViewChange("Monthly")}
          >
            Month
          </button>
          <button
            className={`btn btn-sm ${
              viewMode === "Weekly"
                ? "bg-primary text-white"
                : "bg-white text-primary"
            }`}
            onClick={() => handleViewChange("Weekly")}
          >
            Week
          </button>
        </div>
      </div>
      <ResponsiveContainer height={400}>
        {activeUserLoader ? (
          <div className="w-full d-flex justify-content-center align-items-center" style={{height:"400px"}}>
            <span>Loading...</span>
          </div>
        ): activeUsers.length>0 ?(
        <LineChart
          data={activeUsers? activeUsers:[]}
          margin={{
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            padding={{ left: 20, right: 20 }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            padding={{ top: 20, bottom: 20 }}
          />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="performance"
            strokeWidth={3}
            stroke="#8884d8"
            dot={false}
            activeDot={{ r: 8 }}
          />
        </LineChart>
         ):(
          <div className="w-full d-flex justify-content-center align-items-center" style={{height:"400px"}}>
          <span>No data found</span>
        </div>
         )}
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComp;
