import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { LoginAction } from "store/actions/authAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [activeTab, setActiveTab] = useState('NormalAuth');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const toggle = tab => {
  // 	if (activeTab !== tab) setActiveTab(tab);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      LoginAction(
        { ...formData, Provider: "NormalAuth" },
        (data) => {
			if(data?.IsAdmin===false){
				history.push("/admin/avatars");
			}else{
				history.push("/admin/index");

			}
          setLoading(false);
        },
        (error) => {
          // onError callback
          let errorMessage =
            error.response?.data?.message ||
            "Something went wrong, please try again.";
          console.error("Login error:", error.response?.data);
        //   toast.error(errorMessage);
          setLoading(false);
        }
      )
    );
  };

  return (
    <>
      <ToastContainer />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-4">
            <div className="text-center text-muted mb-5">
              <span className="text-md d-flex justify-content-center">
                Sign In
              </span>
            </div>
            {/* <Nav
							className=' mb-4 cursor-pointer'
							tabs
						>
							<NavItem>
								<NavLink
									className={classnames({
										active: activeTab === 'NormalAuth',
									})}
									onClick={() => {
										toggle('NormalAuth');
									}}
								>
									Sign In with Credentials
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({
										active: activeTab === 'Cognito',
									})}
									onClick={() => {
										toggle('Cognito');
									}}
								>
									Sign In with Cognito
								</NavLink>
							</NavItem>
						</Nav> */}
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Email"
                    type="email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    autoComplete="new-email"
                    disabled={loading}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Password"
                    type="password"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                    autoComplete="new-password"
                    disabled={loading}
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className='custom-control custom-control-alternative custom-checkbox'>
								<input
									className='custom-control-input'
									id='customCheckLogin'
									type='checkbox'
								/>
								<label
									className='custom-control-label'
									htmlFor='customCheckLogin'
								>
									<span className='text-muted'>
										Remember me
									</span>
								</label>
							</div> */}
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </div>
            </Form>
          </CardBody>
          {/* <small className='my-3 '>Don't have an account yet? <span className='text-primary' style={{cursor: 'pointer'}} onClick={() => history.push("/auth/register")}>Register Now</span></small> */}
        </Card>
      </Col>
    </>
  );
};

export default Login;
