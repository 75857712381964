import React, { useEffect } from "react";
import { RxAvatar } from "react-icons/rx";
import { FaChartPie } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import LineChartComp from "components/Analytics/MonthlyChart";
import { UserAnalyticsAction } from "store/actions/avatarAction";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const AnalyticsDetails = () => {
  const dispatch = useDispatch();
  const { analytics, loading, activeUserLoader } = useSelector(
    (state) => state?.create
  );
  const { user } = useSelector((state) => state?.authUser);
  useEffect(() => {
    dispatch(UserAnalyticsAction());
  }, []);
  return (
    <>
        <Card className="shadow ">
          <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
            <h3 className="mb-0">Analytics</h3>
          </CardHeader>
          <Container>
            <Row >
            {user?.IsAdmin && (
              <Col className="p-1">
                <div className="bg-white px-2 py-2 mb-1 border border-black rounded">
                  <div className="d-flex justify-content-between">
                    <span className="text-left">
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-uppercase text-muted font-weight-bold"
                      >
                        Number of Active Users
                      </p>
                      {loading ? (
                        <Skeleton className="w-100 h-25" />
                      ) : (
                        <p className="font-weight-bold">
                          {analytics?.TotalUsers ?? 0}
                        </p>
                      )}
                    </span>
                    <span>
                      <FaUserGroup className="text-primary" size={32} />
                    </span>
                  </div>
                </div>
              </Col>
            )}
              <Col className="p-1">
                <div className="bg-white px-2 py-2 mb-1 border border-black rounded">
                  <div className="d-flex justify-content-between">
                    <span className="text-left">
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-uppercase text-muted font-weight-bold"
                      >
                        Number of Conversions
                      </p>
                      {loading ? (
                        <Skeleton className="w-100 h-25" />
                      ) : (
                        <p className="font-weight-bold">
                          {analytics?.TotalConversations ?? 0}
                        </p>
                      )}
                    </span>
                    <span>
                      <RxAvatar className="text-red" size={32} />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="p-1">
                <div className="bg-white px-2 py-2 mb-1 border border-black rounded">
                  <div className="d-flex justify-content-between">
                    <span className="text-left">
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-uppercase text-muted font-weight-bold"
                      >
                        Duration of Conversation
                      </p>
                      {loading ? (
                        <Skeleton className="w-100 h-25" />
                      ) : (
                        <p className="font-weight-bold">
                          {analytics?.TotalDuration ?? 0}
                        </p>
                      )}
                    </span>
                    <span>
                      <FaChartPie className="text-red" size={32} />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="p-1">
                <div className="bg-white px-2 py-2 mb-1 border border-black rounded">
                  <div className="d-flex justify-content-between">
                    <span className="text-left">
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-uppercase text-muted font-weight-bold"
                      >
                        Query sent 
                      </p>
                      {loading ? (
                        <Skeleton className="w-100 h-25" />
                      ) : (
                        <p className="font-weight-bold">
                          {analytics?.TotalQueries ?? 0}
                        </p>
                      )}
                    </span>
                    <span>
                      <FaCoins className="text-green" size={32} />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="p-1">
                <div className="bg-white px-2 mb-1 py-2 border border-black rounded">
                  <div className="d-flex justify-content-between">
                    <span className="text-left">
                      <p
                        style={{ fontSize: "12px" }}
                        className=" text-uppercase text-muted font-weight-bold"
                      >
                        Number of Token Used
                      </p>
                      {loading ? (
                        <Skeleton className="w-100 h-25" />
                      ) : (
                        <p className="font-weight-bold">
                          {analytics?.TokensUsed ?? 0}
                        </p>
                      )}
                    </span>
                    <span>
                      <FaChartBar className="text-primary" size={32} />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {user?.IsAdmin && (
            // <Container>
              <Row className="m-2">
                <Col>
                  <LineChartComp />
                </Col>
              </Row>
            // </Container>
          )}
        </Card>
    </>
  );
};
export default AnalyticsDetails;
