import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { CreateAvatarAction } from "store/actions/avatarAction";
import { getAvatar } from "store/actions/avatarAction";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const createAvatarSchema = z.object({
  UserName: z.string().min(1, "User Name is required"),
  Email: z.string().email("Invalid email address"),
  FirstName: z
    .string()
    .min(1, "First Name is required")
    .regex(/^[a-zA-Z]+$/, "First Name can only contain letters"),
  LastName: z
    .string()
    .min(1, "Last Name is required")
    .regex(/^[a-zA-Z]+$/, "Last Name can only contain letters"),
  Address: z.string().min(1, "Address is required"),
  Password: z.string().min(6, "Password must be at least 6 characters"),
  City: z.string().min(1, "City is required"),
  Country: z.string().min(1, "Country is required"),
  PostalCode: z.string().min(1, "Postal Code is required"),
  AboutMe: z.string().min(1, "About Me is required"),
});

const CreateAvatarModal = ({ open, setOpen }) => {
  const history = useHistory();
  const { loading } = useSelector((state) => state?.create);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createAvatarSchema),
  });

  const JSalert = () => {
    Swal.fire({
      title: "Congrats!",
      text: "Avatar created successfully!",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const onSubmit = (data) => {
    const payload = {
      ...data,
      Provider: "NormalAuth",
    };
    dispatch(
      CreateAvatarAction(payload, () => {
        JSalert();
        setOpen();
        history.push("/admin/index");
        dispatch(getAvatar());
      })
    );
  };

  return (
    <Modal isOpen={open} size="lg">
      <ModalHeader toggle={setOpen}>Create Avatar for client</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="text">User Name</Label>
                <Controller
                  name="UserName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      id="UserName"
                      placeholder="User Name"
                      invalid={!!errors.UserName}
                    />
                  )}
                />
                {errors.UserName && (
                  <small className="text-danger">
                    {"User Name is required"}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="Email">Email Address</Label>
                <Controller
                  name="Email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="email"
                      id="Email"
                      placeholder="Email"
                      invalid={!!errors.Email}
                    />
                  )}
                />
                {errors.Email && (
                  <small className="text-danger">{errors.Email.message}</small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="FirstName">First Name</Label>
                <Controller
                  name="FirstName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      id="FirstName"
                      placeholder="First Name"
                      invalid={!!errors.FirstName}
                    />
                  )}
                />
                {errors.FirstName && (
                  <small className="text-danger">
                    {errors.FirstName.message}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="LastName">Last Name</Label>
                <Controller
                  name="LastName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      id="LastName"
                      placeholder="Last Name"
                      invalid={!!errors.LastName}
                    />
                  )}
                />
                {errors.LastName && (
                  <small className="text-danger">
                    {errors.LastName.message}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label for="Address">Address</Label>
                <Controller
                  name="Address"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="textarea"
                      id="Address"
                      placeholder="Address"
                      invalid={!!errors.Address}
                    />
                  )}
                />
                {errors.Address && (
                  <small className="text-danger">{"Address is required"}</small>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="Password">Password</Label>
                <Controller
                  name="Password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="password"
                      id="Password"
                      placeholder="Password"
                      invalid={!!errors.Password}
                    />
                  )}
                />
                {errors.Password && (
                  <small className="text-danger">
                    {errors.Password.message}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="City">City</Label>
                <Controller
                  name="City"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="City"
                      placeholder="City"
                      invalid={!!errors.City}
                    />
                  )}
                />
                {errors.City && (
                  <small className="text-danger">{"City is required"}</small>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="Country">Country</Label>
                <Controller
                  name="Country"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="Country"
                      placeholder="Country"
                      invalid={!!errors.Country}
                    />
                  )}
                />
                {errors.Country && (
                  <small className="text-danger">{"Country is required"}</small>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="PostalCode">Postal Code</Label>
                <Controller
                  name="PostalCode"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="PostalCode"
                      placeholder="Postal Code"
                      invalid={!!errors.PostalCode}
                    />
                  )}
                />
                {errors.PostalCode && (
                  <small className="text-danger">
                    {"Postal Code is required"}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <FormGroup>
                <Label for="AboutMe">About Me</Label>
                <Controller
                  name="AboutMe"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="textarea"
                      id="AboutMe"
                      placeholder="About me"
                      invalid={!!errors.AboutMe}
                    />
                  )}
                />
                {errors.AboutMe && (
                  <small className="text-danger">
                    {"About me is required"}
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Button
            disabled={loading}
            className="rounded-pill"
            color="primary"
            type="submit"
          >
            {loading ? <Spinner size="sm" color="light" /> : "Create"}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateAvatarModal;
