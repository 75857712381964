import OnlyHeader from "components/Headers/OnlyHeader";
import React from "react";
import {Container} from "reactstrap";
import "react-loading-skeleton/dist/skeleton.css";
import AnalyticsDetails from "components/Analytics/AnalyticsDetails";
const Analytics = () => {

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <AnalyticsDetails/>
      </Container>
    </>
  );
};
export default Analytics;
