import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import {
	uploadFileAction,
	getAvatarFilesAction,
	deleteAvatarFiles,
} from 'store/actions/avatarAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import DeleteModal from 'components/Modals/DeleteModal';
import { setAvatarData } from 'store/actions/avatarAction';

const Document = props => {
	// const { Id } = useParams();
	const location = useLocation();
	const { Id, title, imageUrl } = location.state?.data;
	const dispatch = useDispatch();
	const { loading, submitLoading, avatarFiles, deleteLoader, avatarData } =
		useSelector(state => state?.create);
	const [error, setError] = useState('');
	const [files, setFiles] = useState([]);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [payload, setPayload] = useState();
	useEffect(() => {
		dispatch(getAvatarFilesAction(Id));
	}, []);
	useEffect(() => {
		dispatch(setAvatarData());
	}, []);

	const handleFiles = (e) => {
		const selectedFiles = Array.from(e.target.files);
		const validFileTypes = ['application/pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
		const validFileExtensions = ['.pdf', '.csv', '.doc', '.docx'];
	  
		const invalidFiles = selectedFiles.filter((file) => {
		  const fileType = file.type;
		  const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
		  return !validFileTypes.includes(fileType) && !validFileExtensions.includes(fileExtension);
		});
	  
		if (invalidFiles.length > 0) {
		  document.getElementById('file').value = '';
		  setError('Please select a valid PDF or CSV document');
		  return;
		}
	  
		const fileSizeLimit = 10 * 1024 * 1024; // 10MB
	  
		const oversizedFiles = selectedFiles.filter((file) => file.size > fileSizeLimit);
	  
		if (oversizedFiles.length > 0) {
		  document.getElementById('file').value = '';
		  setError('File size should not exceed 10MB');
		  return;
		}
	  
		setError('');
		setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
	  };
	  
	const removeFile = index => {
		let temp = [...files];
		temp.splice(index, 1);
		setFiles(temp);
	};
	const onSubmit = () => {
		if (files.length === 0) {
			setError('Please  select at least one file');
			return true;
		}
		const formData = new FormData();
		files.forEach(item => {
			formData.append('QueryFiles', item);
		});
		dispatch(
			uploadFileAction(Id, formData, () => {
				setFiles([]);
				document.getElementById('file').value = '';
				dispatch(getAvatarFilesAction(Id));
			})
		);
	};

	const handleDelete = (e, obj) => {
		e.preventDefault();
		let data = {
			AvatarId: obj?.AvatarId,
			FileName: obj?.FileName,
		};
		setPayload(data);
		// setId(id);
		setDeleteOpen(true);
	};

	const confirmDelete = e => {
		e.preventDefault();
		dispatch(
			deleteAvatarFiles(payload, () => {
				dispatch(getAvatarFilesAction(Id));
				setDeleteOpen(false);
			})
		);
	};
	const CloseModal = () => {
		setDeleteOpen(false);
	};

	return (
		<div>
			<div class='container'>
				<div>
					<h1>File Upload Here</h1>
					<div class='drop-zone'>
						<p>Drop your file here</p>
						<or>Or</or>
						<label className='browse-button'>
							Browse
							<input
								id='file'
								required
								type='file'
								className='hidden-file-input'
								onChange={e => handleFiles(e)}
							/>
						</label>
					</div>
					<div class='file-info'>
						<small className='text-black'>{error}</small>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-12'>
						{files.length > 0 && (
							<table className='table responsive'>
								<thead className=' text-dark'>
									<tr>
										<th className='text-start' scope='col'>
											Selected Files
										</th>

										<th scope='col'>Action</th>
									</tr>
								</thead>

								<tbody>
									<>
										{files.length > 0 &&
											files?.map((obj, index) => (
												<>
													<tr key={index}>
														<td>{obj?.name}</td>
														<td>
															<span>
																<button
																	color='link'
																	disabled={
																		loading
																	}
																	className='cursor-pointer border-0'
																	onClick={() => {
																		removeFile(
																			index
																		);
																	}}
																>
																	<MdDelete
																		className=' text-danger border-none pe-auto cursor-pointer'
																		// onClick={() => {
																		//   removeFile(index);
																		// }}
																		style={{
																			fontSize:
																				'24px',
																			fonrWeight:
																				'400',
																		}}
																	/>
																</button>
															</span>
														</td>
													</tr>
												</>
											))}
										{files?.length == 0 && (
											<>
												<tr>
													<td colspan='2'>
														{' '}
														<div className='mt-2  d-flex align-items-center py-4 justify-content-center w-full'>
															<div>
																No Selected File
															</div>
														</div>
													</td>
												</tr>
											</>
										)}
									</>
								</tbody>
							</table>
						)}
					</div>
				</div>
				<div className='col-12'>
					<button
						className='btn btn-primary pe-auto text-end'
						onClick={onSubmit}
						disabled={submitLoading}
						// disable={submitLoading}
					>
						Submit{' '}
						{submitLoading && <Spinner size='sm' color='light' />}
					</button>
				</div>
				{/* uploaded files section */}
				<div className='col-12 mt-4'>
					{avatarFiles?.length > 0 && (
						<table className='table responsive'>
							<thead className=' text-dark'>
								<tr>
									<th className='text-start' scope='col'>
										Uploaded Files
									</th>

									<th scope='col'>Action</th>
								</tr>
							</thead>

							<tbody>
								<>
									{avatarFiles.length > 0 &&
										avatarFiles?.map((obj, index) => (
											<>
												<tr key={index}>
													<td>{obj?.FileName}</td>
													<td>
														<span>
															<button
																color='link'
																disabled={
																	loading
																}
																className='cursor-pointer border-0'
																onClick={e => {
																	handleDelete(
																		e,
																		obj
																	);
																}}
															>
																<MdDelete
																	className=' text-danger border-none pe-auto cursor-pointer'
																	// onClick={() => {
																	//   removeFile(index);
																	// }}
																	style={{
																		fontSize:
																			'24px',
																		fonrWeight:
																			'400',
																	}}
																/>
															</button>
														</span>
													</td>
												</tr>
											</>
										))}
									{avatarFiles?.length == 0 && (
										<>
											<tr>
												<td colspan='2'>
													{' '}
													<div className='mt-2  d-flex align-items-center py-4 justify-content-center w-full'>
														<div>
															No Selected File
														</div>
													</div>
												</td>
											</tr>
										</>
									)}
								</>
							</tbody>
						</table>
					)}
				</div>
			</div>
			{deleteOpen && (
				<DeleteModal
					DeleteHandler={confirmDelete}
					loading={loading}
					closeModal={CloseModal}
				/>
			)}
		</div>
	);
};

export default Document;
